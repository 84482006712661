import React, { useEffect, useState } from "react";
import { editBrand } from "../../utils/validators/validators";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { updatebrand, Counrtylist } from "../../services/super-admin.service";
import * as Yup from "yup";
import {
  updateBrandStatus,
  Merchantlist,
} from "../../services/merchant.service";
import CloseIcon from "../../assets/images/close_icon.svg";
import { fileUpload } from "../../services/common.service";
import UploadImg from "../../assets/images/upload_img.svg";
import {
  getCategory,
  MerchantDetailByID,
  getTimeZoneList,
} from "../../services/merchant.service";
import BackIcon from "../../assets/images/back_icon.svg";
import SaveIcon from "../../assets/images/save_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setcountry } from "../../store/countrylist/country.reducer";
import { selectcountry } from "../../store/countrylist/country.selector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";
import { selectRole } from "../../store/role/role.selector";
import {
  latitudeRegex,
  longitudeRegex,
  phoneRegex,
  VatIdRegex,
} from "../../utils/Regex";
const fileType = "Merchant";

const EditBrandForm = ({ back, TotallocationCount }) => {
  console.log("TotallocationCount::: ", TotallocationCount);
  const [Category, setCategory] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { id } = useParams();
  const [EditData, SeteditData] = useState([]);
  const [selectedCategory, Setselectedcategory] = useState([]);
  console.log("EditData::: ", EditData);
  const [dialCode, setDialCode] = useState("");
  const role = useSelector(selectRole);
  const location = useLocation();
  const navigate = useNavigate();
  const [isSingleStore, setIsSingleStore] = useState(
    EditData?.[0]?.is_single_store ?? false
  );
  console.log("isSingleStore::: ", isSingleStore);
  const [cashbackpolicy, setIscashbackpolicy] = useState(
    EditData?.[0]?.is_cashback_policy ?? false
  );

  console.log("test", cashbackpolicy);

  const Rewardtype = [
    { name: "Open Loop", value: "openLoop" },
    { name: "Closed Loop", value: "closedLoop" },
  ];

  const storetype = [
    { name: "Offline", value: "OFFLINE" },
    { name: "Online", value: "ONLINE" },
    { name: "Both", value: "BOTH" },
  ];
  const [timezone, setTimezone] = useState([]);
  const [selectedTimezone, setselectedTimezone] = useState();
  console.log("selectedTimezone::: ", selectedTimezone);

  const handleTypeChange3 = (e) => {
    setselectedTimezone(e);
    formikedit.setFieldValue("time_zone", e);
  };

  const countries = useSelector(selectcountry);

  const minlocationcount = TotallocationCount || 0;

  const dispatch = useDispatch();
  const formikedit = useFormik({
    initialValues: {
      id: EditData?.[0]?.id,
      name: EditData?.[0]?.name,
      website_url: EditData?.[0]?.website_url,
      location: EditData?.[0]?.location,
      latitude: EditData?.[0]?.latitude,
      longitude: EditData?.[0]?.longitude,
      android_package_name: EditData?.[0]?.android_package_name,
      ios_package_name: EditData?.[0]?.ios_package_name,
      android_package_url: EditData?.[0]?.android_package_url,
      ios_package_url: EditData?.[0]?.ios_package_url,
      contact_name: EditData?.[0]?.contact_name,
      email: EditData?.[0]?.email,
      mobile: EditData?.[0]?.mobile,
      brand_logo: EditData?.[0]?.brand_logo,
      merchant_vat_id: EditData?.[0]?.merchant_vat_id,
      country: EditData?.[0]?.country,
      city: EditData?.[0]?.city,
      reward_type: EditData?.[0]?.reward_type,
      category_type: selectedCategory,
      dial_code: EditData?.[0]?.dial_code,
      store_count: EditData?.[0]?.store_count ?? 1,
      store_type: EditData?.[0]?.store_type,
      is_single_store: EditData?.[0]?.is_single_store,
      time_zone: selectedTimezone,
      is_cashback_policy: EditData?.[0]?.is_cashback_policy,
      is_cashback_policy_days: EditData?.[0]?.is_cashback_policy_days,
    },

    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email Id")
        .required("Email is required"),
      name: Yup.string()
        .required("Merchant name is required")
        .max(20, "Merchant name must not exceed 20 characters"),

      brand_logo: Yup.string().required("logo is required"),
      contact_name: Yup.string()
        .required("Contact name is required")
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Only alphanumeric characters and spaces are allowed"
        ),
      website_url: Yup.string()
        .required("Website url is required")
        .matches(
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
          'Invalid URL format. Please enter a Website url like "www.brand.com" or "https://www.brand.com".'
        ),
      mobile: Yup.string()
        .notRequired()
        .matches(phoneRegex, "Mobile number is not valid")
        .nullable(true)
        .required("Mobile is required"),
      category_type: Yup.array()
        .min(1, "At least one category must be selected")
        .required("Category type is required"), // Ensure category_type is not an empty array
      time_zone: Yup.array().required("TimeZone is required"), // Ensure category_type is not an empty array
      latitude: Yup.string()
        .matches(latitudeRegex, "Invalid Latitude")
        .nullable(true),
      longitude: Yup.string()
        .matches(longitudeRegex, "Invalid Longitude")
        .nullable(true),
      location: Yup.string().required("Location is required"),
      // android_package_name: Yup.string().required("Andriod package name is required"),
      // .matches(/^[a-zA-Z]+$/, 'Andriod name is must contain only letters'),
      // android_package_url: Yup.string().required("Andriod package url is required"),
      // .matches(
      //   /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      //   'Invalid URL format. Please enter a Website url like "www.brand.com" or "https://www.brand.com".'
      // ),
      // ios_package_name: Yup.string().required("Ios package name is required"),
      // .matches(/^[a-zA-Z]+$/, 'Package Name  is contain only letters'),
      // ios_package_url: Yup.string().required("Ios package url is required"),
      // .matches(
      //   /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      //   'Invalid URL format. Please enter a Website url like "www.brand.com" or "https://www.brand.com".'
      // ),
      reward_type: Yup.string().required("Cash back type is required"),
      city: Yup.string()
        .required("City name is required")
        .matches(
          /^[a-zA-Z0-9\/,\s-]+$/,
          "City Name must contain only letters, numbers, slashes, hyphens, and commas"
        ),
      country: Yup.string().required("Country is required"),
      merchant_vat_id: Yup.string()
        // .notRequired()
        .matches(VatIdRegex, "Vat id number is not valid")
        // .nullable(true)
        .required("Vat is required"),
      store_count: Yup.number()

        .typeError("Store Count must be a number")
        .positive("Store Count must be greater than 0")
        .integer("Store Count must be an integer")
        .required("Store Count is required"),

      store_type: Yup.string().required("Merchant Type is required"),
      time_zone: Yup.string()
        .transform((value) =>
          typeof value === "object" && value !== null ? value.value : value
        )
        .required("TimeZone is required"),
      is_cashback_policy_days: Yup.number()
        .typeError("Cashback Return Policy Days must be a number")
        .positive("Cashback Return Policy Days must be greater than 0")
        .integer("Cashback Return Policy Days must be an integer")
        .required("Cashback Return Policy Days is Required"),
     
    }),
    onSubmit: async (values) => {
      console.log("values::: ", values);
      values.store_count = +values.store_count;
      values.is_cashback_policy_days = +values.is_cashback_policy_days;
      values.time_zone = values.time_zone.value;

      try {
        const res = await updatebrand(values);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          formikedit.resetForm();
          formikedit.setErrors({});
          if (role == "admin" || role == "superadmin") {
            back();
          } else {
            navigate(location.pathname, { replace: true });
          }
          // getMerchantDetail()
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });

  const handleTypeChange1 = (e) => {
    console.log("e::: ", e);
    const { name, value, id } = e.target;
    console.log(name, value, id, "JJJJJJJJJJJJ");
    formikedit.setFieldValue(name, value == "false" ? false : true);
    setIsSingleStore(value == "false" || undefined ? false : true);
    formikedit.setFieldValue("store_count", EditData?.[0]?.store_count ?? 1);
  };

  const handleTypechangecashbackpolicy = (e) => {
    console.log('e::: ', e);
    const { name, value, id } = e.target;
    formikedit.setFieldValue(name, value == "false" ? false : true);
    setIscashbackpolicy(value == "false" || undefined ? false : true);
    formikedit.setFieldValue(
      "is_cashback_policy_days",
      EditData?.[0]?.is_cashback_policy_days ?? 1
    );
  };
  const handleTypeChange2 = (e) => {
    console.log("e::: ", e);
    const minStoreCount = minlocationcount || 0;
    const newValue = parseInt(e.target.value, 10);
    console.log("newValue::: ", newValue);

    if (newValue >= minStoreCount || isNaN(newValue)) {
      formikedit.handleChange(e);
    }
  };

  const handleTypeChange4 = (e) => {
    const newValue = parseInt(e.target.value, 10);
    const validValue = isNaN(newValue) ? 0 : newValue;
    formikedit.setFieldValue("is_cashback_policy_days", validValue);
  };

  const handleEditFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
        if (res?.data?.statusCode === 200) {
          formikedit.setFieldValue("brand_logo", res?.data?.data);
        }
      }
    } catch (error) {}
  };

  const editRemoveLogo = () => {
    formikedit.setFieldValue("brand_logo", "");
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;

    formikedit.setFieldValue("country", selectedCountry);

    const country = countries.find((c) => c.name === selectedCountry);
    const selectedDialCode = country ? country.dialCode : "";

    setDialCode(selectedDialCode);
    formikedit.setFieldValue("dial_code", selectedDialCode);
  };

  useEffect(() => {
    const getCountry = async () => {
      try {
        const res = await Counrtylist();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          const countries = res.data.data.map((country) => ({
            name: country.name,
            dialCode: country.dial,
          }));
          dispatch(setcountry(countries));
          // setBrand(res?.data?.getallbrand || []);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    const getMerchantDetail = async () => {
      try {
        const res = await MerchantDetailByID(id);
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          console.log("res::: ", res);
          SeteditData(res?.data?.data?.getAllMerchant);
          Setselectedcategory(res?.data?.data?.merchant_category);
          setselectedTimezone(res?.data?.data?.getAllMerchant?.[0]?.time_zone);
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    const getCategorylist = async () => {
      try {
        const res = await getCategory();

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          if (res?.data?.data) {
            setCategory(
              res?.data?.data?.map((category) => ({
                label: category.category_name,
                value: category.id,
              }))
            );
          }
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };

    const Timezonelist = async () => {
      try {
        const res = await getTimeZoneList();
        if (res?.status === 200 && res?.data?.statusCode === 200) {
          if (res?.data?.data) {
            const timezoneOptions = res.data.data.map((item, index) => ({
              value: item.tz,
              id: item.index,

              label: item.tz,
            }));

            setTimezone(timezoneOptions);
          }
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    getCategorylist();
    getMerchantDetail();
    getCountry();
    Timezonelist();
  }, []);

  const handleTypeChange = (e) => {
    formikedit?.setFieldValue("category_type", e);
  };

  return (
    <div>
      <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween BorderBottom_LavenderSyrup pb_16 Mob_flexWrapGap">
        <div className="displayFlex alignItem_center gap12">
          <div>
            {/* onClick={handleClick} */}
            <button className="emptyBtn" onClick={back}>
              <img src={ArrowleftIcon} alt="ArrowleftIcon" />
            </button>
          </div>
          <div>
            <h5 className="fontSize20 fontWeight600 blackClr_text">
              Edit Merchant Details
            </h5>
          </div>
        </div>
      </div>
      <form onSubmit={formikedit.handleSubmit}>
        <div className="row">
          {/* =========    Brand Name ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Merchant Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Merchant Name"
              id="name"
              name="name"
              value={formikedit.values.name}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.name && formikedit.touched.name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.name}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Merchant Logo
            </label>
            {!formikedit.values?.brand_logo && (
              <label
                htmlFor="selectedLogo"
                className="uploadImg_btn gap12 mb_24"
              >
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Merchant Logo</span>
                <input
                  id="selectedLogo"
                  type="file"
                  name="brand_logo"
                  value={formikedit.values.brand_logo}
                  accept="image/*" // Accept all image file types
                  onChange={handleEditFileChange}
                  onBlur={formikedit.handleBlur}
                  hidden
                />
              </label>
            )}

            {formikedit.values?.brand_logo && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  loading="lazy"
                  src={formikedit.values?.brand_logo}
                  width={100}
                  height={100}
                  alt="selected logo"
                />
                <div className="imgcloseBtn ">
                  <img
                    src={CloseIcon}
                    alt="CloseIcon"
                    onClick={editRemoveLogo}
                  />
                </div>
              </div>
            )}

            {!formikedit.values.brand_logo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.brand_logo}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Merchant Type
            </label>
            <select
              className="selectMainInput"
              id="store_type"
              placeholder="store_type"
              name="store_type"
              value={formikedit.values.store_type}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            >
              <option value="">---Merchant Type---</option>
              {storetype.map((city, index) => (
                <option key={index} value={city.value}>
                  {city.name}
                </option>
              ))}
            </select>

            {formikedit.errors.store_type && formikedit.touched.store_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.store_type}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* ========= Website URL ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Website URL
            </label>
            <input
              className="MainInput"
              type="website_url"
              placeholder="Enter Website URL "
              id="website_url"
              name="website_url"
              value={formikedit.values.website_url}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.website_url && formikedit.touched.website_url ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.website_url}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Contact Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Contact Name"
              id="contact_name"
              name="contact_name"
              value={formikedit.values.contact_name}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.contact_name &&
            formikedit.touched.contact_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.contact_name}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= Email ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="MainInput"
              type="email"
              placeholder="Enter Email"
              id="email"
              name="email"
              value={formikedit.values.email}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.email && formikedit.touched.email ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.email}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/* ========= Country ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Country
            </label>
            <select
              className="selectMainInput"
              id="country"
              placeholder="country"
              name="country"
              value={formikedit.values.country}
              onChange={(e) =>
                handleCountryChange(e, formikedit.setFieldValue, setDialCode)
              }
              onBlur={formikedit.handleBlur}
            >
              <option value="">---Select Country---</option>
              {countries.map((city, index) => (
                <option key={index}>{city.name}</option>
              ))}
            </select>

            {formikedit.errors.country && formikedit.touched.country ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.country}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= City ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              City
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter City"
              id="city"
              name="city"
              value={formikedit.values.city}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.city && formikedit.touched.city ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.city}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Location=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Location
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Location"
              id="location"
              name="location"
              value={formikedit.values.location}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.location && formikedit.touched.location ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.location}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              TimeZone
            </label>

            <Select
              value={timezone.find(
                (option) => option.value === formikedit.values.time_zone
              )}
              onChange={handleTypeChange3}
              options={timezone}
            />

            {formikedit.errors.time_zone && formikedit.touched.time_zone ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.time_zone}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Mobile
            </label>
            <div className="row">
              <div className="col-md-2 pRzero">
                <div className="MainInputFrame">
                  {" "}
                  {formikedit.values.dial_code || "-"}
                </div>
              </div>
              <div className="col-md-10">
                <input
                  className="MainInput flex-grow-1"
                  type="text"
                  placeholder="Enter Mobile"
                  id="mobile"
                  name="mobile"
                  value={formikedit.values.mobile}
                  onChange={formikedit.handleChange}
                  onBlur={formikedit.handleBlur}
                />
              </div>
            </div>

            {formikedit.errors.mobile && formikedit.touched.mobile ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.mobile}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= VAT ID ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Vat Id
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Vat Id"
              id="merchant_vat_id"
              name="merchant_vat_id"
              value={formikedit.values.merchant_vat_id}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.merchant_vat_id &&
            formikedit.touched.merchant_vat_id ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.merchant_vat_id}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6 mob_mb24">
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="toggleValueInput"
              >
                Store Type
              </label>
              <div className="displayFlex alignItem_center gap12">
                <div className="prefer_method">
                  <input
                    type="radio"
                    id="is_single_store"
                    name="is_single_store"
                    value={true}
                    onChange={(e) => handleTypeChange1(e)}
                    checked={formikedit.values.is_single_store}
                    // onChange={formikedit.handleChange}
                    onBlur={formikedit.handleBlur}
                  />
                  <label htmlFor="is_single_store">Single Store</label>
                </div>
                <div className="prefer_method">
                  <input
                    type="radio"
                    id="is_multiple_store"
                    name="is_single_store"
                    value={false}
                    onChange={(e) => handleTypeChange1(e)}
                    checked={!formikedit.values.is_single_store}
                  />
                  <label htmlFor="is_multiple_store">Mutli Store</label>
                </div>
              </div>
              {formikedit.errors.is_single_store &&
              formikedit.touched.is_single_store ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.is_single_store}
                </p>
              ) : (
                <></>
              )}
            </div>

            {!formikedit.values.is_single_store && (
              <div className="">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="email"
                >
                  Multiple Store
                </label>
                <input
                  className="MainInput"
                  type="text"
                  placeholder="Enter Store Count"
                  id="store_count"
                  name="store_count"
                  value={formikedit.values.store_count}
                  onChange={(e) => handleTypeChange2(e)}
                  onBlur={formikedit.handleBlur}
                />
                {formikedit.errors.store_count &&
                formikedit.touched.store_count ? (
                  <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                    {formikedit.errors.store_count}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          <div className="mb_24 col-md-6 mob_mb24">
            <div className="mb_24">
              <label
                className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
                htmlFor="toggleValueInput"
              >
                Cashback Return Policy
              </label>
              <div className="displayFlex alignItem_center gap12">
                <div className="prefer_method">
                  <input
                    type="radio"
                    id="yes"
                    name="is_cashback_policy"
                    value={false}
                    onChange={(e) => handleTypechangecashbackpolicy(e)}
                    checked={!formikedit.values.is_cashback_policy}
                  />
                  <label htmlFor="yes">Yes</label>
                </div>
                <div className="prefer_method">
                  <input
                    type="radio"
                    id="no"
                    name="is_cashback_policy"
                    value={true}
                    onChange={(e) => handleTypechangecashbackpolicy(e)}
                    checked={formikedit.values.is_cashback_policy}
                    onBlur={formikedit.handleBlur}
                  />
                  <label htmlFor="no">NO</label>
                </div>
              </div>
              {formikedit.errors.is_cashback_policy &&
              formikedit.touched.is_cashback_policy ? (
                <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                  {formikedit.errors.is_cashback_policy}
                </p>
              ) : (
                <></>
              )}
            </div>

            {!formikedit.values.is_cashback_policy && (
              <div className="">
                <label
                  className="fontSize14 fontWeight500 blackClr_text display_block mb_16"
                  htmlFor="email"
                >
                  Cashback Return policy days
                </label>
                <input
                  className="MainInput"
                  type="text"
                  placeholder="Enter Cashback Return Policy Days"
                  id="is_cashback_policy_days"
                  name="is_cashback_policy_days"
                  value={formikedit.values.is_cashback_policy_days}
                  onChange={(e) => handleTypeChange4(e)}
                  onBlur={formikedit.handleBlur}
                />
                {formikedit.errors.is_cashback_policy_days &&
                formikedit.touched.is_cashback_policy_days ? (
                  <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                    {formikedit.errors.is_cashback_policy_days}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>

          {/*===========Latitude=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Latitude
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Latitude"
              id="latitude"
              name="latitude"
              value={formikedit.values.latitude}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.latitude && formikedit.touched.latitude ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.latitude}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========Longitude=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Longitude
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Longitude"
              id="longitude"
              name="longitude"
              value={formikedit.values.longitude}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.longitude && formikedit.touched.longitude ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.longitude}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/*===========ANDROID PACKAGE NAME=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Android Package Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Android Package Name"
              id="android_package_name"
              name="android_package_name"
              value={formikedit.values.android_package_name}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.android_package_name &&
            formikedit.touched.android_package_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.android_package_name}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/*===========ANDROID PACKAGE URL=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Android Package Url
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Android Package Url"
              id="android_package_url"
              name="android_package_url"
              value={formikedit.values.android_package_url}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.android_package_url &&
            formikedit.touched.android_package_url ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.android_package_url}
              </p>
            ) : (
              <></>
            )}
          </div>
          {/*===========IOS PACKAGE NAME=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              IOS Package Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter IOS Package Name"
              id="ios_package_name"
              name="ios_package_name"
              value={formikedit.values.ios_package_name}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.ios_package_name &&
            formikedit.touched.ios_package_name ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.ios_package_name}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/*===========IOS PACKAGE URL=========*/}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              IOS Package Url
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter IOS Package Url"
              id="ios_package_url"
              name="ios_package_url"
              value={formikedit.values.ios_package_url}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            />
            {formikedit.errors.ios_package_url &&
            formikedit.touched.ios_package_url ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.ios_package_url}
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* ========= Reward  Type ========= */}
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Cash Type
            </label>
            <select
              className="selectMainInput"
              id="reward_type"
              placeholder="reward type"
              // id="city"
              name="reward_type"
              value={formikedit.values.reward_type}
              onChange={formikedit.handleChange}
              onBlur={formikedit.handleBlur}
            >
              <option>---Cash Type---</option>
              {Rewardtype.map((city, index) => (
                <option value={city.value} key={index}>
                  {city.name}
                </option>
              ))}
            </select>

            {formikedit.errors.reward_type && formikedit.touched.reward_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.reward_type}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="email"
            >
              Category Select
            </label>

            <Select
              value={formikedit.values.category_type}
              onChange={handleTypeChange}
              options={Category}
              isMulti={true}
            />

            {formikedit.errors.category_type &&
            formikedit.touched.category_type ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {formikedit.errors.category_type}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap8">
          <img src={SaveIcon} alt="SaveIcon" />
          <span> Save changes</span>
        </button>
      </form>
    </div>
  );
};

export default EditBrandForm;
