import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getBrandList } from "../../services/super-admin.service";
import { useNavigate } from "react-router-dom";

import AddIcon from "../../assets/images/add_icon.svg";
import UpdateIcon from "../../assets/images/update_icon.svg";
import ViewIcon from "../../assets/images/view_icon.svg";
import CreateBrand from "./CreateBrandForm";
import EditBrandForm from "./EditBrandForm";
import PaginatedTable from "../common/PaginatedTable";
import ViewBrandDetail from "./ViewBrandDetails";
import ApiSecret from "../Api_secretkey/ApiSecret";
import DatePicker from "../DatePicker/DatePicker";
import { exportMerchant } from "../../services/export.service";
import { convertToISOString } from "../../services/common.service";
// import ApiSecret from "../Api_secretkey/ApiSecret";
import {
  getLocation,
  // updateCashBackRules,
  // removeCashBackRules
} from "../../services/Location.service";



function Brandingpage() {
  const [createForm, setCreateForm] = useState(false);
  const [brandTable, setBrandTable] = useState(true);
  const [editform, seteditform] = useState(false);
  const [Viewform, setViewform] = useState(false);
  const [brand, setbrand] = useState(null);
  const [TotallocationCount, setTotallocationCount] = useState(null);
  console.log('TotallocationCount::: ', TotallocationCount);


  const [data, setData] = useState({});
  const navigate = useNavigate();

  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });
  const [status, setStatus] = useState("");
  console.log('status::: ', status);

  const handleShow = () => {
    setCreateForm(true);
    setBrandTable(false);
  };


  const handleExport = async () => {
    try {
    
      let payload = {
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),
        search: searchKey
      }
      await exportMerchant(payload)
    } catch (error) {

    }
  }

  const back = (list = true) => {
    list && getAllBrand();
    setBrandTable(true);
    setViewform(false);
    setCreateForm(false);
    seteditform(false);
    setData({});
  };

  const handleShowedit = (brand) => {
    setData(brand);
    seteditform(true);
    setBrandTable(false);
  };

  const handleShowView = (brand) => {
    setData(brand);
    setViewform(true);
    setBrandTable(false);
  };

  const getAllBrand = async () => {
    const offset = (currentPage - 1) * itemsPerPage;
    const offsetToUse = offset >= 0 ? offset : 0
    // offset = (currentPage - 1) * itemsPerPage;
    try {
      const requestBody = {
        offset: offsetToUse,
        limit: itemsPerPage,
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),
        search: searchKey,
        statusfilter: status || "all"
      };
      const resp = await getBrandList(requestBody);
    

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setbrand(resp?.data?.getallbrand);
        setTotalCount(resp?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  const getLocationdetails = async (id) => {
    try {
      const requestBody = {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
        search: searchKey,

        parent_merchant_id: id,
        startDate: range.startDate,
        endDate: range.endDate,
      };
      const resp = await getLocation(requestBody);
      console.log("resp::: ", resp);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        // setbrand(resp?.data?.getallbrand);
        setTotallocationCount(resp?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };


  const [column, setColumn] = useState([
    {
      heading: () => "Action",
      cell: (row) => (
        <>
          <button className="emptyBtn" onClick={() => navigate(`/admin/brand-detail/${row?.id}`)}>
            <img
              src={ViewIcon}
              alt="ViewIcon"
            />
          </button>
        </>
      ),
      hidden: false,
       // width: "120",
      // align: "left",
      id: 1,
    },
    {
      heading: () => "Merchant Name",
      cell: (row) => (
        <div className="textWrap_Wrap">
        {row["name"] ? row["name"] : "-"}
      </div>
        
      ),

      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => "Email",
      cell: (row) => (row["email"] === " " ? "-" : row["email"]),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    {
      heading: () => "Mobile",
      cell: (row) => {
       
        const dialCode = row.dial_code || "+971"; // Use an empty string if no dial code
        const mobile = row.mobile || "-"; // Use a dash if no mobile number
        return dialCode ? `${dialCode}-${mobile}` : mobile;
      },
      // cell: (row) => (row["mobile"] ? row["mobile"] : "-"),
      hidden: false,
      width: "100",
      id: 5,
    },
    {
      heading: () => "Contact Name",
      cell: (row) => (row["contact_name"] ? row["contact_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 9,
    },
    {
      heading: () => "Website Url",
      cell: (row) => (row["website_url"] ? row["website_url"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 4,
    },
    // {
    //   heading: () => "Status",
    //   cell: (row) => (
    //     <span className={row?.active_flag ? "fontWeight600 UFOGreen_text" : "fontWeight600 errorClr_text"}>
    //       {row["active_flag"] ? "Active" : "Inactive"}
    //     </span>
    //   ),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 9,
    // },

    {
      heading: () => "Status",
      cell: (row) => {
        console.log('row::: ', row);
        const status = row["active_flag"];
        console.log('status::: ', status);
        let displayText = "";
        let style = {};
        let className = "";

        if (status === true) {
          displayText = "Active";
          
        } else if (status === false) {
          displayText = "InActive";
          className = "fontWeight600 errorClr_text"; // Use class names directly
        } else if (status === "all") {
          displayText = "ALL Status";
          style = { color: "#28a745", fontWeight: 600 };
        } else {
          displayText = "-";
          style = { fontWeight: 600 };
        }

        return <span className={row?.active_flag ? "fontWeight600 UFOGreen_text" : "fontWeight600 errorClr_text"} style={style}>{displayText}</span>;
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
  ]);

  useEffect(() => {
    getAllBrand();
    // getLocationdetails(id)
  }, [currentPage, itemsPerPage, searchKey, range, setSearchKey,status]);

  return (
    <div>
      {brandTable && (
        <>
          <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                Merchants List
              </h5>
            </div>
            
            <div className="displayFlex justifyContent_spacebetween">
                            {/* <div className="pr_10"> <DatePicker range={range} setRange={setRange} /></div> */}
            
              <button
                className="MainButton cyanSky_clr gap8"
                type="button"
                onClick={handleShow}
                 // onClick={() => navigate("/add-coach")}
              >
                <img className="width20px" src={AddIcon} alt="AddIcon" />
                <span> Create Merchant </span>
              </button>
            </div>
          </div>
         

          {isLoading ? (
            <PaginatedTable
              row={brand}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              setRange={setRange}
              status={status}
              setStatus={setStatus}
              isstatus={true}
              range={range}
              isExport={true}
              isDateRange={true}
              handleExport={() => handleExport()}
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
           
        </>
      )}

      {createForm && <CreateBrand back={back} />}

      {editform && <ApiSecret back={back} />}

      {Viewform && <ViewBrandDetail brand={data}  campaign={data} back={back} /> }
    </div>
  );
}

export default Brandingpage;